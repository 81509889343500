body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

:root {
  --primary: #5e3bee;
  --heading-color: #282938;
  --bg--shade: #f5fcff;
  --github: #e62872;
  --darkblue: #1c1e53;
  --black: #000000;
  --white: #ffffff;
  --first-color: hsl(353, 100%, 65%);
  --title-color: hsl(244, 24%, 26%);
  --text-color: hsl(244, 16%, 43%);
  --body-color: hsl(258, 60%, 98%);
  --container-color: #fff;

}

.btn {
  font-family: "roboto";
  display: inline-block;
  font-weight: 600;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 21.33px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}

.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}

.btn-primary {
  color: var(--white);
  background-color: #AA37AF !important;
  border: 1px solid var(--primary);
}

.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}

.btn-github {
  color: var(--white);
  background-color: var(--github);
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;

}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}


h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;

}

h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}


.text-lg {
  color: var(--darkblue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.text-md {
  color: var(--darkblue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}


.section--title {
  color: var(--heading-color);
  font-size: 25px;
  text-align: left;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}


.sub--title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}


/* Navbar Container */
.custom-navbar .navbar {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: var(--body-color) !important; /* Ensure your background applies */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: background-color 0.3s ease-in-out; /* Fix property name */
  z-index: 100;
  /* padding: 16px 0; */  
}

.custom-navbar .navbar--items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  padding: 0;
  gap: 42.667px;
  margin: 0; /* Reset any default margins from Bootstrap */
}

.custom-navbar .navbar--items ul > li > a {
  text-decoration: none !important; /* Override Bootstrap's underline */
}

.custom-navbar .navbar--contentt {
  color: var(--darkblue) !important; /* Override default Bootstrap link color */
  font-size: 16px;
  font-style: normal;
  font-weight: 1000;
  line-height: 24px;
  cursor: pointer;
  text-transform: none; /* Ensure Bootstrap text-transform doesn't apply */
}

.custom-navbar .navbar--active-content {
  color: #AA37AF !important; /* Override Bootstrap's active link style */
}

/* Transparent Navbar (if needed) */
/* .navbar.transparent {
  background: transparent !important;
} */

/* White Background Navbar */
.custom-navbar .navbar.white {
  background-color: #ffffff !important;
}


/* Skills Section */
#skills.skills-section {
  background-color: var(--body-color);
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  padding-bottom: 10%;
}

.section-title {
  font-size: 36px;
  font-weight: 700;
  margin-top: 100px;
  margin-bottom: 10px;
  /* color: #AA37AF !important; */
  text-align: center;
}

/* Skill Icon Styling */
.skills-list {
  font-size: 180%;
  color: #AA37AF !important;
  text-align: center;
  position: relative;
  list-style: none; /* Prevent Bootstrap styles from affecting list */
  padding: 0; /* Reset padding */
  margin: 0 auto; /* Center the list */
}

.skills-list-item {
  display: inline-block; /* Inline list items */
  margin: 0 15px; /* Add consistent spacing */
}

.skills-tile {
	background-color: transparentize(white, 0.95);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.skills-name {
  font-size: 50px;
  margin-top: 8px;
  color: var(--darkblue) !important;
}

/* Center the Title */
.skills-title-container {
  text-align: center;
  margin-bottom: 20px;
}

/* Center Content */
.skills-list-container {
  text-align: center;
}




/*Hero section */
.hero--section {
  display: grid;

  grid-template-columns: 1fr 1fr;
  padding: 133.333px 85.333px 133.333px;
  align-items: start;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 32px;

  height: auto;
  background-color: var(--body-color);
}

.hero--section--content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-area: content;
  gap: 32px;
}

.hero--section--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}

.hero--section--content--box>button {
  margin-top: 21.333px;

}

.hero--section--title {
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  justify-content: center;
  color: #283D7B;
}

.hero--section--title--1 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  justify-content: center;
  color: #283D7B;
}

.hero--section--title--color {
  color: #283D7B;
  text-align: center;
}

.hero--section--title--description {
  color: var(--darkblue);
  font-size: 24px;
  font-weight: 400;
  line-height: 100px;
  text-align: center;
  font-style: italic;
}

.hero--section--img {
  display: none; /* Hide the original image section */
}

.polaroid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.polaroid {
  position: relative;
  width: 300px; /* You can adjust the width */
  height: 380px; /* Adjust the height */
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transform: rotate(10deg); /* Tilt the polaroid to the right */
  overflow: hidden;
  padding: 10px;
  text-align: center;
}

.polaroid-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.polaroid-caption {
  font-size: 16px;
  font-weight: bold;
  color: #283D7B;
  margin-top: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}


.hero--section--description {
  font-size: 1rem;
  line-height: 2;
  color: #333;
  margin-bottom: 1rem;
}


.button-and-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.social-icons a {
  color: #AA37AF;
  text-decoration: none;
}

.social-icons a:hover {
  color: gray;
}

@media (max-width: 768px) {
  .hero--section {
    grid-template-columns: 1fr; /* Stacks elements vertically */
    padding: 50px 20px; /* Reduce padding for smaller screens */
    gap: 16px; /* Reduce spacing */
  }

  .hero--section--title {
    font-size: 36px; /* Smaller font size */
    line-height: 48px;
  }

  .hero--section--description {
    font-size: 0.875rem; /* Reduce description text size */
    line-height: 1.5;
  }

  .polaroid-container {
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }

  .polaroid {
    width: 200px; /* Adjust the size */
    height: auto;
    transform: rotate(5deg); /* Adjust tilt for smaller screens */
  }
}


/*Experience*/

#resume {
  background-color: var(--body-color);
  padding: 50px 0; /* Added padding for better layout */
}


/* Experience Icon */
.experience-icon {
  font-size: 300%;
  margin-top: 25%;
  text-align: center;
}

/* Badge styling for regular tech */
.main-badge {
  font-size: 13px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #AE944f !important;
  color: white;
  font-family: "Arial", sans-serif; 
  font-weight: bold !important; /* Make text bold */
}


.experience-badge {
  font-size: 11px !important;
  text-align: left !important;
  padding: 5px 8px 5px 8px !important;
  vertical-align: baseline;
  background-color: #AE944f !important;
  /* color: black; */
  font-weight: bold !important; /* Make text bold */
  font-family: "Arial", sans-serif; 
}

.vertical-timeline-element-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}

.vertical-timeline-element-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #6c757d; /* Optional lighter color */
  margin-bottom: 12px;
}

/* Description Text */
.vertical-timeline-element p {
  font-size: 14px;
  line-height: 1.5;
  color: #333;
}

/* Adjusting Timeline Styles */
.vertical-timeline-element-date {
  padding: 0 !important;
  margin: 0 !important;
}

/* Media Query for Responsiveness */
@media only screen and (max-width: 1170px) {
  .experience-icon {
    font-size: 170%;
    margin-top: 27%;
    text-align: center;
  }
}

.portfolio--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 42.6px;
  flex-wrap: wrap;
  background-color: var(--body-color);
  padding: 20px;
}

.portfolio--section--heading {
  font-size: 36px;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  /* color: #AA37AF; */
  margin-top: 50px;
  margin-bottom: 20px;
  margin-top: 2rem;
}

.portfolio--container--box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.portfolio--container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.portfolio--section--container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}


.portfolio--section--img {
  height: 200px;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolio--section--img>img {
  border-radius: 8px;
  width: 100%;

}

.portfolio--section--img img {
  transition: opacity 0.3s ease;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
  border-radius: 8px;
}

.portfolio--section--img img:hover {
  opacity: 0.8;
}

.portfolio--section--card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 calc(33.333% - 42.6px);
  gap: 32px;
  background: #fff;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: box-shadow 0.3s ease, transform 0.3s ease, height 0.3s ease;
  height: 380px;
}

.portfolio--section--card:hover {
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px);
  height: auto;
}

.portfolio--section--card--content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  overflow: hidden;
  justify-content: space-between;
  transition: height 0.3s ease;
}

.portfolio--section--title {
  color: var(--heading-color);
}

.portfolio--link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}

.portfolio--section--card:hover path {
  stroke: #006b6a;
}

.portfolio--section--card.hovered .portfolio--section--img {
  display: none;
}


.footer--container {
  display: flex;
  padding: 70px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background-color: #604f71;
  align-items: center;
  align-self: stretch;
}

.footer--link--container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer--social--icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: color 0.3s ease;

}

.footer--social--icon:hover {
  color: white;
}

.footer--social--icon>li {
  margin: 0 100px;
}

.footer--items>ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 42.667px;
  text-decoration: none;
  justify-content: center;
}

.footer--items ul>li>a {
  text-decoration: none;
}

.footer--social--icon>ul {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 16px; */
  flex-shrink: 0;
  text-decoration: none;
  gap: 50px;
}

.divider {
  height: 1px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}

.footer--content--container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration-color: #565656;
  font-weight: 200;
  padding: 10px;
}

.footer--content {
  color: #D4D4D4;
  font-size: 18.667px;
  line-height: 28px;
  text-align: center;
}

.footer--social--icon>ul>li>a {
  transition: color 0.3s ease;
  color: #D4D4D4;
  text-decoration: none;
}

.footer--social--icon>ul>li>a:hover {
  color: white;
}

.footer--social--icon ul>li:hover {
  transform: scale(1.3);
}


@media only screen and (max-width: 1800px) {

  .skills--section--title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills--section--heading {
    font-size: 54px;
    line-height: 70px;
  }

  .skills--section--container {
    gap: 16px;
  }

  .skills--section--card {
    gap: 28px;
  }

  .skills--section--card--content {
    gap: 20px;
  }

  .skills--section--title {
    font-size: 23px;
    line-height: 30px;
  }

  .skills--section--description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .skills--section--container,
  .about--section,
  .portfolio--section--container,
  .portfolio--container-box,
  .footer--link--container,
  .footer--items>ul,
  .footer--content--container {
    display: flex;
    flex-direction: column;
  }

  .skills--section,
  .portfolio--container,
  {
    align-items: center;
  }

  .portfolio--container-box {
    gap: 30px;
  }

  .skills--section,
  .testimonial--section,
  .contact--section,
  .footer--container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .about--section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .testimonial--section--card {
    padding: 25px;
  }


  .skills--section--heading,
  .sections--heading {
    font-size: 35px;
    line-height: 40px;
  }

  .hero--section--content--box {
    gap: 10px;
  }

  .contact--form--container {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .footer--social--icon>ul,
  .footer--items>ul {
    padding: 0;
    align-items: center;
  }

  .divider {
    margin: 20px;
  }
}


.nav__hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 25px;
  right: 25px;
}

.nav__hamburger__line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav__hamburger {
    display: flex;
    z-index: 200;
  }

  .navbar--items {
    display: flex;
  }

  .navbar--items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -20rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
  }

  .navbar--items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }

  .navbar--items ul li {
    text-align: center;
  }

  .navbar--items ul li a {
    padding: 0.5rem;
  }

  .navbar--items {
    z-index: -1000;
  }

  .navbar--items.active {
    top: 30px;
  }

  .nav__hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav__hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav__hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}


.skills--section {
  padding: 80px 20px;
  text-align: center;
  height: 100%;
  overflow: hidden;
  background-color: var(--body-color);
  ;
}

.s--container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.s--heading {
  font-size: 36px;
  font-weight: 700;
  color: #AA37AF;
  margin-bottom: 20px;
}
.skill-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #283D7B;
}

.swiper_container {
  width: 100%;
  padding-bottom: 40px;
}

.skill-card {
  background: #ffffff;
  padding: 20px;
  text-align: left;
  height: 100%;
  border: none;
  box-shadow: none;
  transition: filter 0.3s ease;
  width: 400px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
}



.skill-description {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
  color: #283D7B;
}

.skill-description li {
  font-size: 16px;
  margin-bottom: 8px;
  justify-content: center;
}

.swiper-slide {
  width: 100px;
  height: 400px;
  padding: 10px;
  overflow: hidden;
  border: none;
  box-shadow: none;
}

.slider-controler {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  border: none;
  font-size: 50px;
  color: #333;
}

.swiper-pagination {
  text-align: center;
  color: #333;
}

.swiper-pagination-bullet {
  background: #333;
}

.swiper-pagination-bullet-active {
  background: #ff0000;
}

.skill-card,
.swiper-slide {
  border: none !important;
  outline: none !important;
}

.skill-card-active {
  box-shadow: none;
  filter: brightness(1);
}

.machine-learning {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  column-gap: 20px;
}

.machine-learning li {
  break-inside: avoid;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
}

/* Responsive settings for skills swiper */
@media screen and (max-width: 1200px) {
  .skills--section {
    padding: 40px 20px;
    text-align: center;
  }

  .s--container {
    max-width: 1000px;
  }

  .s--heading {
    font-size: 30px;
    margin-bottom: 15px;
  }

  .swiper_container {
    padding-bottom: 20px;
  }

  .skill-card {
    padding: 10px;
    width: 300px;
    height: 150px;
    text-align: center;
  }

  .skill-title {
    font-size: 20px;
  }

  .skill-description {
    font-size: 14px;
    padding-left: 10px;
  }

  .swiper-slide {
    width: 300px;
    height: 200px;
  }

  .machine-learning {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }

  .machine-learning li {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .slider-controler {
    justify-content: space-around;
    gap: 5px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    font-size: 30px;
  }

  .swiper-pagination {
    font-size: 12px;
    margin-top: 10px;
  }
}

.resume .container .section {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.875rem;
  gap: 1rem;
  height: 100vh;
  background-color: var(--body-color);
}

.resume {
  background-color: var(--body-color);
  padding: 20px;
  height: auto;
}

.timeline {
  background-color: var(--white);
  padding-left: 3rem;
  padding-bottom: 3rem;
  border-radius: 20px;
  position: relative;
  width: 90%;
  /* box-shadow: 0px 5px 20px 0px rgb(69, 67, 97); */
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 5rem;
}

.timeline__item {
  position: relative;
  padding-bottom: 1.5rem;
  padding-left: 2rem;
  padding-right: 1rem;
  margin: 1rem;
  background-color: var(--white);
}

.timeline__item:last-child {
  padding-bottom: 0;
  margin-bottom: 0.5rem;
}

.timeline__item::before {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0.25rem;
  top: 0;
  z-index: 1;
  background-color: #283D7B;
}

.timeline .icon-briefcase,
.timeline .icon-graduation {
  position: absolute;
  left: -0.4375rem;
  top: 0;
  color: #AA37AF;
  background-color: var(--white);
  padding: 0.4375rem 0;
  z-index: 2;
}

.timeline__date {
  color: #283D7B;
  font-size: 0.813rem;
}

.timeline__title {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #AA37AF;

}

.timeline__place,
.timeline__location {
  color: #283D7B;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.timeline__text {
  font-size: 1rem;
  line-height: 2;
  color: #333;
  margin-bottom: 1rem;
}

.resume__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--body-color);
}

.resume__section {
  flex: 1;
  padding: 1rem;
}

.section__cards {
  display: flex;
  flex-direction: column;
}

.section__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.card {
  margin-bottom: 1rem;
}

.timeline__item:not(:last-child) {
  margin-bottom: 1rem;
}

.resume__container.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  column-gap: 2rem;
  background-color: var(--body-color);
}

.r--heading {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  background-color: var(--body-color);
  color: #AA37AF;
}

@media screen and (max-width: 1200px) {
  .resume__container.grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .timeline {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }

  .timeline__item {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }

  .timeline__item:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  .timeline__date,
  .timeline__place,
 .timeline__location,
.timeline__text {
    font-size: 0.9rem;
  }

  .timeline .icon-briefcase,
  .timeline .icon-graduation {
    left: -0.25rem;
    padding: 0.25rem;
  }

  .section__title,
  .r--heading {
    font-size: 1.25rem;
  }
}



/* PRELOADER */

.preloader{
  height: 100vh;
  width: 100%;
  background-color: var( --body-color);
  color: #AA37AF;
  position: fixed;
  bottom:0;
  left: 0;
  right: 0;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.preloader .texts-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: 280px;
  font-size: 20px;
  font-weight: 800;
  overflow: hidden;
  color: #AA37AF;


}