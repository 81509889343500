.experience-container {
    /* margin: 4rem 0; */
    padding: 6rem;
    position: relative;
    background-color: hsl(258, 60%, 98%);
    /* width: 100%; */
    height: 100%;
    padding-top: 9rem;

}

.experience-container h5 {
    /* font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem ; */
    background-color: hsl(258, 60%, 98%);
    font-size: 36px;
    /* Adjust font size */
    font-weight: 700;
    /* Bold font */
    /* color: #333; Darker text color */
    margin-bottom: 20px;
    /* Spacing below the heading */
    justify-content: center;
    display: flex;
    background-color: var(--body-color);
    color: #AA37AF;

}

.skill-experience-card h6 {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #AA37AF;
}

.skill-experience-card {
    background-color: #ffffff;
    border-radius: 0.65rem;
    border: 1.5px solid #AA37AF;
    padding: 1.5rem;
    margin: 0 1rem;
    width: auto;
    display: flex;
}

.skill-experience-card {
    font-size: 1.05rem;
    font-weight: 500;
    margin-bottom: 0.7rem;
}

.skill-experience-card ul li {
    list-style: none;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
    position: relative;
    /* justify-content: center; */
    /* display: flex; */
}

.skill-experience-card ul li::after {
    content: " ";
    width: 0.5rem;
    height: 0.5rem;
    background-color: #AA37AF;
    position: absolute;
    left: -1.3rem;
    top: 0.35rem;
}

.arrow-left,
.arrow-right {
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #AA37AF;
    border-radius: 0.65rem;
    border: 1.5px solid #AA37AF;
    position: absolute;
    top: 55%;
    z-index: 2;
    cursor: pointer;
    background-color: #ffffff;
}


.arrow-left span,
.arrow-right span {
    font-size: 2rem;
    font-weight: 500;
}

.arrow-left {

    left: 3rem;
}

.arrow-right {
    right: 3rem;
}

@media (max-width: 1025px) {
    .arrow-left {
        left: 0rem;
    }

    .arrow-right {
        left: 0rem;
    }
}


@media (max-width:768px) {
    .work-experience-card {
        margin: 0;
    }

    .work-experience-card h6 {
        font-size: 0.95rem;
    }

    .work-experience-card ul li {
        font-size: 0.75rem;
    }

    .experience-container h5 {
        font-size: 1.3rem;
        margin-bottom: 2rem;
    }

    .arrow-left {
        left: -1rem;
    }

    .arrow-right {
        right: -1rem;
    }
}